.companyContainer {
  background: linear-gradient(#ec53b0, #7d7cf9);
}
.companyInfoSubContainer {
  display: flex !important;
  align-items: center !important;
  padding: 25px 16px !important;
  border: 2px solid #fbfbfb !important;
  border-left: none !important;
}
.companyInfoIcon {
  position: absolute;
  transform: translate(-100%, 0%);
  font-size: 40px;
  color: #fbfbfb !important;

  @media (min-width: 340px) and (max-width: 440px) {
    font-size: 32px !important;
  }
}

.companyInfoContent {
  margin-left: 10px;
}

.companyInfoValue {
  font-size: 20px !important;
  position: relative !important;
  color: #fbfbfb !important;
}

.companyInfoText {
  font-family: "Circular-400" !important;
  font-size: 32px !important;

  @media (min-width: 340px) and (max-width: 440px) {
    font-size: 22px !important;
  }
  @media (min-width: 440px) and (max-width: 767px) {
    font-size: 28px !important;
  }
  @media (min-width: 768px) {
    font-size: 30px !important;
  }
}

.plusIcon {
  position: absolute !important;
  font-size: 16px !important;
  margin-left: 5px !important;
  color: #fbfbfb !important;
}

.label {
  font-family: "Circular-900" !important;
  font-size: 16px !important;
  text-wrap: nowrap !important;
  color: #fbfbfb !important;

  @media (max-width: 360px) {
    font-size: 12px !important;
  }
  @media (min-width: 361px) and (max-width: 439px) {
    font-size: 13px !important;
  }
  @media (min-width: 440px) and (max-width: 767px) {
    font-size: 15px !important;
  }
  @media (min-width: 768px) {
    font-size: 16px !important;
  }
}
