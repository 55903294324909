.copyrightContainer {
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    padding: 20px 0px !important;
  }
  .copyrightTitle{
    font-family: 'Circular-400' !important;
    font-size: 15px !important;
    color: #fbfbfb !important;

    @media (min-width: 340px) and (max-width: 400px) {
      font-size: 13px !important;
    }
  }
