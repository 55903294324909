.footerText{
    font-family: 'Circular-900' !important;
    font-size: 15px !important;
    display: flex !important;
    align-items: center !important;
}
.footerTitle{
    font-family: 'Circular-400' !important;
    font-size: 20px !important;
}
.contactDetails{
    display: flex !important;
    justify-content: center !important;
}
