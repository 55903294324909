.whyChooseUs,
.whatWeDoTitle {
  font-family: "Circular-400" !important;
  font-size: 20px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center !important;
}
.whatWeTitle {
  border-radius: 40px !important;
  /* padding: 10px 50px !important; */
  font-size: 28px !important;
  font-family: "Circular-500" !important;
  text-align: center !important;
  margin-bottom: 15px !important;
}
.whatWeDoContentContainer {
  padding-bottom: 14px !important;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(to right, #ec53b0, #7d7cf9) 1;
}
.whatWeDoTitle {
  font-size: 16px !important;
  text-align: start !important;
}
.whatWeDescription {
  font-family: "Circular-400" !important;
  font-size: 15px !important;
}
