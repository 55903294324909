@font-face {
  font-family: "Circular-400";
  src: url("../public/fonts/CircularStd-Medium.ttf");
}
@font-face {
  font-family: "Circular-500";
  src: url("../public/fonts/CircularStd-Black.ttf");
}
@font-face {
  font-family: "Circular-700";
  src: url("../public/fonts/CircularStd-Bold.ttf");
}
@font-face {
  font-family: "Circular-900";
  src: url("../public/fonts/CircularStd-Book.ttf");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

body {
  background-color: #fbfbfb;
  color: #191919;
  overflow-x: hidden !important;
  /* transition: background-color 0.3s, color 0.3s; */
}

body[data-theme="dark"] {
  background-color: #191919 !important;
  color: #fbfbfb !important;
}

/* button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 1rem;
  border: none;
  background-color: #007bff;
  color: #fbfbfb !important;
  transition: background-color 0.3s;
}

body[data-theme="dark"] button {
  background-color: #191919;
  color: #fbfbfb;
} */
