.factorSubContainer,
.factorDefine,
.factorData {
  background: #7d7cf9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  padding: 20px !important;
  border-radius: 20px !important;
}
.factorDefine {
  background: linear-gradient(to left, #ec53b0, #7d7cf9) !important;
  position: relative !important;
}
.factorData {
  background: #ec53b0 !important;
}
.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.5;
}

.factorSubContainer:hover .logoWrapper {
  transform: scale(2);
  opacity: 1;
}

.textWrapper {
  background-color: #fbfbfb !important;
  padding: 5px 20px !important;
  border-radius: 35px !important;
  transition: all 0.3s ease;
}
.factorDefine:hover .textWrapper {
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
}

.factorText {
  font-family: "Circular-700" !important;
  font-size: 22px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.factorServices {
  font-family: "Circular-400" !important;
  font-size: 18px !important;
  color: #fbfbfb !important;
}

.gradiantColor,
.factorDefx,
.appsContainer {
  position: relative;
  border-radius: 20px;
  padding: 20px;
  display: flex !important;
  align-items: center !important;
}

.gradiantColor::before,
.factorDefx::before,
.appsContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}
.factorCraft {
  font-size: 16px !important;
  font-family: "Circular-400" !important;
  text-align: center !important;
}
.factorProject,
.appsTitle {
  font-family: "Circular-400" !important;
  font-size: 20px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center !important;
}
.factorButton {
  display: flex !important;
  justify-content: center !important;
}
.factorDefx {
  font-family: "Circular-400" !important;
  font-size: 16px !important;
  padding: 8px 20px !important;
  border-radius: 10px !important;
  cursor: pointer !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
  }
}
.appsIcon img {
  transition: transform 0.3s ease;
}
.appsContainer {
  transition: transform 0.3s ease, opacity 0.3s ease;
  padding: 15px 19px !important;

  &:hover .appsIcon img {
    transform: scale(1.4);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}
.appsContainer:hover .appsTitle,
.appsContainer:hover .appsDescription,
.appsContainer:hover .appsIcon{
  opacity: 1 !important;
}
.appsTitle {
  opacity: 0.5 !important;
  font-size: 18px !important;
  transition: opacity 0.3s ease;
}
.appsDescription {
  opacity: 0.5 !important;
  font-family: "Circular-400" !important;
  font-size: 14px !important;
  transition: opacity 0.3s ease;
}
.appsIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5 !important;
  transition: opacity 0.3s ease;
}
