.navContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 25px !important;
}
.navItem {
  font-family: "Circular-400" !important;
  cursor: pointer !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
}
.themeIcon {
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
}
.themeIcon:focus {
  outline: none;
  background-color: transparent;
}

.themeIcon:active {
  background-color: transparent;
}
