.titleContainer {
  border-bottom: 1px solid transparent !important;
  border-image: linear-gradient(to right, #ec53b0, #7d7cf9) 1 !important;
}
.whoWeAreTitle {
  padding: 0px 10px !important;
  font-family: "Circular-500" !important;
  font-size: 40px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 35px !important;
  }
}
.imageContainer {
  position: relative !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 400px !important;
  z-index: 1;
}
.imageContainer::before {
  content: "";
  position: absolute !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  inset: 0 !important;
  z-index: -1 !important;
  opacity: 0.8;
}
.whoWeAreDescription {
  font-family: "Circular-400" !important;
  font-size: 18px !important;
  color: #fbfbfb !important;
  text-align: center !important;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 15px !important;
  }
}
.teamHighlightsContainer {
  border-top: 2px solid transparent !important;
  border-image: linear-gradient(to right, #ec53b0, #7d7cf9) 1 !important;
}
.teamHighlightsTitle {
  font-family: "Circular-400" !important;
  font-size: 30px !important;
  text-align: center !important;
}
.teamHighlightsHeading {
  font-family: "Circular-400" !important;
  font-size: 20px !important;
}
.teamHighlightsdescription,
.technologiesText {
  font-family: "Circular-900" !important;
  font-size: 16px !important;
}
.techStackContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.textWrapper {
  background-color: #fbfbfb !important;
  padding: 5px 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.6);
}
.techStackText {
  font-family: "Circular-700" !important;
  font-size: 18px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  @media (min-width: 1000px) and (max-width: 1249px) {
    font-size: 16px !important;
  }
  @media (min-width: 900px) and (max-width: 999px) {
    font-size: 15px !important;
  }
}
.technologiesContainer {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 35px 2px !important;
  border: 1px solid transparent !important;
  border-image: linear-gradient(#ec53b0, #7d7cf9) 1 !important;
}
.technologiesText {
  font-size: 16px !important;
}
.talkToExpertContainer {
  position: relative;
  padding: 10px 40px !important;
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
  border-image: linear-gradient(to right, #ec53b0, #7d7cf9) 1 !important;
  cursor: pointer !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    border-radius: 4px !important;
  }
  
}
.talkToExpertContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 4px !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;

}
.talkToExpertText {
  font-family: "Circular-900" !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}
