.tickcerContainer{
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    padding: 10px 0px !important;
}
.stockTicker {
    position: relative !important;
    /* background-color: #ffffff !important; */
    z-index: 2 !important;
    font-size: 16px;
    padding-block: 8px;
    overflow: hidden;
    user-select: none;
    width: 100% !important;
  
    --gap: 30px;
    display: flex;
    gap: var(--gap);
  }
  
  .stockTicker ul {
    font-family: "Circular-400" !important;
    font-size: 16px !important;
    color:#fbfbfb !important;
    flex-shrink: 0;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
  
    animation: scroll 20s linear infinite;
  }

  @keyframes scroll {
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }