.faqContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.faqText {
  font-family: "Circular-700" !important;
  font-size: 18px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.textWrapper {
  background-color: #fbfbfb !important;
  padding: 5px 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.6);
}
.faqHeading {
  border-radius: 40px !important;
  font-size: 28px !important;
  font-family: "Circular-500" !important;
  text-align: center !important;
  margin-bottom: 6px !important;
}
.faqTitle {
  font-family: "Circular-400" !important;
  font-size: 15px !important;
}
.faqContent {
  font-family: "Circular-900" !important;
  font-size: 14px !important;
}
.gradiantColor {
  position: relative;
  border-radius: 3px;
  font-family: "Circular-400" !important;
  font-size: 14px !important;   
}

.gradiantColor::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}
