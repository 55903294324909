.animatedText {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  font-family: "Circular-400" !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  text-align: center !important;
  line-height: 1.5 !important;

  @media (min-width: 640px) {
    font-size: 48px !important;
    line-height: 60.72px !important;
  }

  @media (min-width: 340px) and (max-width: 425px) {
    font-size: 25px !important;
  }
}

p[data-linear-wipe] span {
  background: linear-gradient(to right, #ec53b0 0%, #7d7cf9 40%, #fbfbfb33 90%);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-size: 400% auto !important;
  animation: textShine 4s ease-in-out infinite alternate;
  animation-duration: 7s;
}

@keyframes textShine {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.keyframes {
  animation: animateGradient 3s ease-in-out infinite alternate; /* Slower animation */
}

@keyframes animateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* .heroDataWrapper {
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 50px;
}

.heroDataContent {
  border-radius: 40px !important;
  padding: 3px !important;
}
.heroData { 
  border-radius: 40px !important;
  padding: 10px 50px !important;
  font-size: 28px !important;
  font-family: "Circular-500" !important;
  text-align: center !important;

  @media (max-width: 640px) {
    font-size: 20px !important;
    padding: 10px 40px !important;
  }

  @media (min-width: 300px) and (max-width: 370px) {
    font-size: 15px !important;
  }
  @media (min-width: 371px) and (max-width: 428px) {
    font-size: 17px !important;
  }
} */
.heroDescription {
  font-size: 19px !important;
  font-family: "Circular-400" !important;
  text-align: center !important;

  @media (min-width: 340px) and (max-width: 425px) {
    font-size: 16px !important;
  }
}

.heroButtonWrapper {
  position: relative;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  background-size: 300% 300%;
  border-radius: 5px;
  padding: 1px;
  animation: borderAndShadowAnimation 10s infinite;

  &:hover {
    animation: none !important;
    padding: 1px;
    background: transparent !important;
  }
}
.heroButtonContent {
  border-radius: 5px !important;
}
.heroButton {
  border-radius: 5px !important;
  padding: 8px 30px !important;
  font-size: 20px !important;
  font-family: "Circular-500" !important;
  cursor: pointer !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
  }

  @media (min-width: 340px) and (max-width: 425px) {
    font-size: 16px !important;
  }
}

@keyframes borderAndShadowAnimation {
  0% {
    box-shadow: 0 0 10px rgba(125, 124, 249, 0.5),
      0 0 20px rgba(236, 83, 176, 0.5);
  }
  25% {
    box-shadow: 3px 0 10px rgba(125, 124, 249, 0.5),
      0 3px 10px rgba(125, 124, 249, 0.5);
  }
  50% {
    box-shadow: -3px 0 10px rgba(236, 83, 176, 0.5),
      0 -3px 10px rgba(236, 83, 176, 0.5);
  }
  75% {
    box-shadow: 3px 0 10px rgba(125, 124, 249, 0.5),
      0 3px 10px rgba(125, 124, 249, 0.5);
  }
  100% {
    box-shadow: -3px 0 10px rgba(236, 83, 176, 0.5),
      0 -3px 10px rgba(236, 83, 176, 0.5);
  }
}

/* @keyframes rotate-border {
  0% {
    background-position: 0% 50%;
    opacity: 1;
  }
  25% {
    background-position: 100% 50%;
    opacity: 0.3;
  }
  50% {
    background-position: 100% 100%;
    opacity: 0.3;
  }
  75% {
    background-position: 0% 100%;
    opacity: 1;
  }
  100% {
    background-position: 0% 50%;
    opacity: 0.3;
  }
} */

.entrustData {
  position: relative;
  font-family: "Circular-400" !important;
  font-size: 20px !important;
  padding: 8px 30px !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;

  box-shadow: 0 0 4px 2px rgba(236, 83, 176, 0.5),
    0 0 8px 2px rgba(125, 124, 249, 0.7);

  border: 1px solid transparent;
  background-image: linear-gradient(to right, #ec53b0, #7d7cf9);
  background-origin: border-box;
  background-clip: content-box, border-box;

  animation: rotateBorder 7s infinite linear;

  &:hover {
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    border-width: 1px !important;
    box-shadow: none !important;
    animation: none !important;
  }

  @media (min-width: 340px) and (max-width: 425px) {
    font-size: 16px !important;
  }
}

@keyframes rotateBorder {
  0% {
    border-color: #ec53b0;
  }
  25% {
    border-color: #7d7cf9;
  }
  50% {
    border-color: #ec53b0;
  }
  75% {
    border-color: #7d7cf9;
  }
  100% {
    border-color: #ec53b0;
  }
}

.heroData {
  position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px 50px !important;
  font-size: 28px !important;
  font-family: "Circular-500" !important;
  text-align: center !important;

  @media (max-width: 640px) {
    font-size: 20px !important;
    padding: 10px 40px !important;
  }
  @media (min-width: 300px) and (max-width: 370px) {
    font-size: 15px !important;
  }
  @media (min-width: 371px) and (max-width: 428px) {
    font-size: 17px !important;
  }
}

.heroData::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 40px !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

/* @keyframes borderAndShadowAnimation {

  0% {
    box-shadow: 0 0 10px rgba(125, 124, 249, 0.5),
      0 0 20px rgba(236, 83, 176, 0.5);
  }
  25% {
    box-shadow: 3px 0 10px rgba(125, 124, 249, 0.5),
      0 3px 10px rgba(125, 124, 249, 0.5);
  }
  50% {
    box-shadow: -3px 0 10px rgba(236, 83, 176, 0.5),
      0 -3px 10px rgba(236, 83, 176, 0.5);
  }
  75% {
    box-shadow: 3px 0 10px rgba(125, 124, 249, 0.5),
      0 3px 10px rgba(125, 124, 249, 0.5);
  }
  100% {
    box-shadow: -3px 0 10px rgba(236, 83, 176, 0.5),
      0 -3px 10px rgba(236, 83, 176, 0.5);
  }
} */
