.blogTitle {
  font-family: "Circular-500" !important;
  font-size: 32px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  line-height: 1.2 !important;
}
.mainHeading {
  font-family: "Circular-700" !important;
  font-size: 22px !important;
}
.heading {
  font-family: "Circular-700" !important;
  font-size: 20px !important;
}
.text{
    font-family: "Circular-900" !important;
  font-size: 15px !important;
}