.blogMainTitle {
  font-family: "Circular-400" !important;
  font-size: 35px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.imageContainer {
  position: relative !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 5px !important;
  z-index: 1;
}
.imageContainer::before {
  content: "";
  position: absolute !important;
  /* background: #191919 !important; */
  border-radius: 5px !important;
  /* background: linear-gradient(#ec53b0, #7d7cf9) !important; */
  inset: 0 !important;
  z-index: -1 !important;
  opacity: 0.4;

  @media (min-width: 0px) and (max-width: 600px) {
    background: none !important;
    opacity: 1 !important;
  }
}
.blogTitleImage {
  font-family: "Circular-400" !important;
  font-size: 20px !important;
  color: #fbfbfb !important;
  text-align: center !important;
  opacity: 1;
  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 16px !important;
  }
}
.titleData {
  padding: 30px;
  background: linear-gradient(
    to right,
    rgba(236, 83, 176, 0.8),
    rgba(125, 124, 249, 0.8)
  ) !important;
  width: 100% !important;
  @media (min-width: 0px) and (max-width: 600px) {
    padding: 20px !important;
  }
}
.titleCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.image {
  object-fit: cover !important;
  width: 100% !important;
  cursor: pointer !important;
  border-radius: 3px !important;
}
.blogTitle,
.blogTitleSec {
  font-family: "Circular-400" !important;
  font-size: 18px !important;
  cursor: pointer !important;
}
.blogTitleSec {
  font-size: 17px !important;
}
.author,
.authorSec {
  font-family: "Circular-900" !important;
  font-size: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.authorSec {
  font-size: 13px !important;
}
.blogDescription,
.blogDescriptionSec {
  font-family: "Circular-900" !important;
  font-size: 16px !important;
  cursor: pointer !important;
}
.blogDescriptionSec {
  font-size: 15px !important;
}
.blogText {
  font-family: "Circular-900" !important;
  font-size: 18px !important;
}
.blogHeading {
  font-family: "Circular-400" !important;
  /* font-size: 20px !important; */
}
.categoryItem,
.timeToRead {
  font-family: "Circular-900" !important;
  font-size: 14px !important;
  opacity: 0.7 !important;
}
.timeToRead {
  font-size: 13px !important;
}
.blogDescriptionBold {
  font-family: "Circular-400" !important;
}
.blogList {
  font-family: "Circular-900" !important;
  font-size: 18px !important;
  /* margin-bottom: 10px !important; */
  line-height: 1.5 !important;
}
.blogListItem {
  /* margin-bottom: 50px !important; */
  line-height: 1.5 !important;
}
.imageCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.shareText {
  font-family: "Circular-900" !important;
  color: #191919 !important;
}
.shareData {
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  padding: 8px !important;

  &:hover {
    background-color: #e0e0e0 !important;
    border-radius: 5px !important;
  }
}
.imageCredit {
  opacity: 0.6 !important;
  font-family: "Circular-900" !important;
  font-style: italic !important;
  /* margin-bottom: 20px !important; */
  text-align: center !important;
  font-size: 14px !important;
}
.blogDescriptionCode {
  background-color: #f5f5f5;
  padding: 2px 4px;
  font-family: Courier New, Courier, monospace;
  border-radius: 3px;
  color: #d6336c;
  font-size: 16px !important;
}
.blogItalic {
  font-family: "Circular-900" !important;
  font-style: italic !important;
}
.loadMore {
  font-family: "Circular-900" !important;
  position: relative;
  border-radius: 5px;
  padding: 10px 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  font-size: 15px !important;
}
.loadMore::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
