.serviceTitle {
  font-family: "Circular-500" !important;
  font-size: 28px !important;
}
.imageContainer {
  padding: 15px 1px 1px 1px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  border-radius: 3px !important;
  height: 100% !important;
}
.imageText {
  font-family: "Circular-400" !important;
  font-size: 17px !important;
  text-align: center !important;
  padding: 20px 4px !important;

  @media (min-width: 900px) and (max-width: 1300px) {
    font-size: 15px !important;
  }
  @media (min-width: 600px) and (max-width: 700px) {
    font-size: 16px !important;
  }
}
