.teamTitle {
  font-family: "Circular-400" !important;
  font-size: 17px !important;
  text-align: center !important;

  @media (min-width: 451px) and (max-width: 790px) {
    font-size: 16px !important;
  }
  @media (min-width: 300px) and (max-width: 450px) {
    font-size: 15px !important;
  }
}
.teamHeading {
  font-family: "Circular-900" !important;
  font-size: 15px !important;
  text-align: center !important;

  @media (min-width: 451px) and (max-width: 790px) {
    font-size: 14px !important;
  }

  @media (min-width: 300px) and (max-width: 450px) {
    font-size: 13px !important;
  }
}
.itemCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.gradiantColor {
  position: relative;
  padding: 10px 20px !important;
  display: flex !important;
  align-items: center !important;
}

.gradiantColor::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
  transition: background 1000ms ease;
}
.indexes {
  border: 2px solid #ccc;
  opacity: 0.5 !important;
  padding: 10px 20px !important;
  display: flex !important;
  align-items: center !important;
  transition: border-color 500ms ease, box-shadow 500ms ease;
}

.swiperSlide .swiperSlideConten {
  opacity: 0.5 !important;
  transition: opacity 0.3s ease;
}

.swiperSlideActive .swiperSlideConten {
  opacity: 1 !important;
}
.swiperSlideContent {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  /* margin-top: 50px !important; */
}

.activeSlide {
  margin-bottom: 100px !important;
  opacity: 1;
}
/* 
  .swiperSlideContent {
    transition: all 0.3s ease-in-out;
  } */

.swiperSlideContent.inactiveSlide {
  margin-top: 50px;
  margin-bottom: 0;
  opacity: 0.5;
}
.swiperSlideContent.activeSlide {
  margin-top: 0;
  margin-bottom: 100px;
  opacity: 1;
}
.image {
  object-fit: cover;
  width: 140px;
  height: 140px;

  @media (min-width: 650px) and (max-width: 770px) {
    width: 130px;
    height: 130px;
  }

  @media (min-width: 401px) and (max-width: 649px) {
    width: 110px;
    height: 110px;
  }

  @media (min-width: 300px) and (max-width: 400px) {
    width: 100px;
    height: 100px;
  }
}

.imageActive {
  filter: none;
}

.imageInactive {
  filter: grayscale(80%);
}
