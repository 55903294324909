.contactUsContainer {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
  }
  .contactUsSubContainer{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .factorSubContainer {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    padding: 20px 30px !important;
  }
  .textWrapper {
    background-color: #fbfbfb !important;
    padding: 6px 15px !important;
    border-radius: 3px !important;
    text-align: center !important;
  }
  .contactUsText {
    font-family: "Circular-700" !important;
    font-size: 14px !important;
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    cursor: pointer !important;
  }
  .contactUsTitle{
    font-family: 'Circular-900' !important;
    font-size:16px !important;
    color: #fbfbfb !important;
  }
  .contactUsHeading{
    font-family: 'Circular-500' !important;
    font-size:20px !important;
    color: #fbfbfb !important;
  }
  