.container {
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;

  @media (min-width: 0px) and (max-width: 899px) {
    border: none !important;
  }
}
.testimonialsTextData,
.testimonialsTextData2 {
  font-family: "Circular-500" !important;
  writing-mode: vertical-rl;
  text-orientation: revert;
  white-space: nowrap;
  font-size: 65px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transform: rotate(180deg);
  width: 100% !important;

  @media (min-width: 1200px) and (max-width: 1480px) {
    font-size: 55px !important;
  }
  @media (min-width: 1070px) and (max-width: 1199px) {
    font-size: 56px !important;
  }
  @media (min-width: 900px) and (max-width: 1078px) {
    font-size: 57px !important;
  }
}
.testimonialsTextData2 {
  writing-mode: vertical-rl;
  transform: rotate(360deg);
}
.testimonialsContent {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #fbfbfb !important;
}
.testimonialsContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.textWrapper {
  background-color: #fbfbfb !important;
  padding: 5px 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.6);
}
.testimonialsText {
  font-family: "Circular-700" !important;
  font-size: 18px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  @media (min-width: 1000px) and (max-width: 1249px) {
    font-size: 16px !important;
  }
  @media (min-width: 900px) and (max-width: 999px) {
    font-size: 15px !important;
  }
}
.testimonialHeading {
  border-radius: 40px !important;
  /* padding: 10px 50px !important; */
  font-size: 27px !important;
  font-family: "Circular-500" !important;
  text-align: center !important;
  margin-bottom: 6px !important;
  line-height: 30px !important;

  @media (min-width: 1250px) and (max-width: 1480px) {
    font-size: 25px !important;
  }

  @media (min-width: 1000px) and (max-width: 1249px) {
    font-size: 22px !important;
  }
  @media (min-width: 800px) and (max-width: 999px) {
    font-size: 19px !important;
  }
  @media (min-width: 300px) and (max-width: 799px) {
    font-size: 25px !important;
  }
}
.testimonialsDataTitle,
.testimonialsDataDescription {
  font-size: 17px !important;
  font-family: "Circular-900" !important;
  text-align: center !important;
}
.testimonialsDataDescription {
  font-size: 14px !important;

  @media (min-width: 900px) and (max-width: 1250px) {
    font-size: 13px !important;
  }
}
.gradiantColor {
  position: relative;
  display: flex !important;
  align-items: center !important;
}

.gradiantColor::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right,#ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}
