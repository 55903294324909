.entrustTitle {
  font-family: "Circular-400" !important;
  font-size: 20px !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.entrustDescription {
  font-family: "Circular-500" !important;
  font-size: 23px !important;
}
/* .entrustButtonWrapper {
  position: relative !important;
  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  background-size: 300% 300% !important;
  border-radius: 5px !important;
  padding: 1px;

  &:hover {
    padding: 1px;
    background: transparent !important;
  }
}
.entrustButtonContent {
  border-radius: 5px !important;
}
.entrustButton {
  border-radius: 5px !important;
  padding: 8px !important;
  text-transform: none !important;
  font-family: "Circular-400" !important;
  font-size: 16px !important;
  text-align: center !important;
  cursor: pointer !important;

  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  &:hover {
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    -webkit-text-fill-color: unset !important;
  }
  @media (min-width: 360px) and (max-width: 550px) {
    font-size: 15px !important;
  }
} */

.entrustButton {
  position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Circular-400" !important;
  font-size: 16px !important;
  padding: 8px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  
  &:hover {
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    -webkit-text-fill-color: unset !important;
  }
  @media (min-width: 360px) and (max-width: 550px) {
    font-size: 15px !important;
  }
}

.entrustButton::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 4px !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

