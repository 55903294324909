.productsTitle {
  font-size: 28px !important;
  font-family: "Circular-500" !important;
}
.itemTextContainer {
  display: flex !important;
  align-items: center !important;
}
.productsItemTitle {
  font-family: "Circular-500" !important;
  font-size: 18px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.productsItemDescription {
  font-size: 15px !important;
  font-family: "Circular-900" !important;
}
.gradiantColor {
  position: relative;
  border-radius: 3px;
  padding: 6px 20px !important;
  font-family: "Circular-400" !important;
  font-size: 14px !important;
  background: linear-gradient(#ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-transform: none !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    border-radius: 4px !important;
  }
}
.entrustButton {
  position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Circular-400" !important;
  font-size: 14px !important;
  padding: 8px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  
  &:hover {
    background: linear-gradient(to right, #ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    -webkit-text-fill-color: unset !important;
  }
  @media (min-width: 360px) and (max-width: 550px) {
    font-size: 15px !important;
  }
}

.entrustButton::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 4px !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

/* .gradiantColor::before{
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
} */

.talkToExpertContainer {
  position: relative;
  padding: 10px 40px !important;
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
  border-image: linear-gradient(to right, #ec53b0, #7d7cf9) 1 !important;
  cursor: pointer !important;

  &:hover {
    background: linear-gradient(#ec53b0, #7d7cf9) !important;
    color: #fbfbfb !important;
    border-radius: 4px !important;
  }
  
}
.talkToExpertContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  border-radius: 4px !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;

}
.productGradient {
  position: relative;
  display: flex !important;
  align-items: center !important;
}

.productGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  background: linear-gradient(to right,#ec53b0, #7d7cf9);
  border-radius: inherit;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}