.crossLineContainer,
.crossLineContainer2 {
  background: linear-gradient(to right, #ec53b0, #7d7cf9);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 50px;
  position: absolute;
  left: -2% !important;
  right: -2% !important;
  /* width: 100% !important; */
  transform-origin: center;
  overflow: hidden !important;
}

.crossLineContainer {
  transform: rotate(5deg);
  @media (min-width: 500px) and (max-width: 700px) {
    transform: rotate(6deg);
  }
  @media (min-width: 300px) and (max-width: 499px) {
    transform: rotate(9deg);
  }
}


.crossLineContainer2 {
  background: linear-gradient(to left, #ec53b0, #7d7cf9);
  transform: rotate(-5deg);
  @media (min-width: 500px) and (max-width: 700px) {
    transform: rotate(-6deg);
  }
  @media (min-width: 300px) and (max-width: 499px) {
    transform: rotate(-9deg);
  }
}
.crossLineTitle {
  font-family: "Circular-400" !important;
  font-size: 16px !important;
  color: #fbfbfb !important;
  white-space: nowrap !important;
}


